import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import Footer from "../components/footer"
import Navi from "../components/navi"
import HeroImage from "../components/heroImage"
import TextBlock from "../components/textBlock"
import FormBlock from "../components/formBlock"
import CategoriesBlock from "../components/categoriesBlock"
import Spacer from "../components/spacer"
import '../styles/index.scss';
import heroimage from "../images/wedding_header.jpg"
import rastatthero from "../images/wedding_header-rastatt.jpg"
import CategoryImage1 from "../images/categorieImg1.jpg"
import CategoryImage2 from "../images/categorieImg2.jpg"
import CategoryImage3 from "../images/categorieImg3.jpg"
import CategoryImage4 from "../images/categorieImg4.jpg"
import CategoryImage5 from "../images/categorieImg5.jpg"
import CategoryImage6 from "../images/categorieImg6.jpg"
import CategoryImage7 from "../images/categorieImg7.jpg"
import CategoryImage8 from "../images/categorieImg8.jpg"




function Index() {
  const categoriesContent =[
    {text: "Steffi und Alex", img: CategoryImage1, link:"/steffi-und-alex/"},
    {text: "Charlotte und Hannes", img: CategoryImage2, link:"/hannes-und-charlotte/"},
    {text: "Mascha und Maxim", img: CategoryImage3, link:"/maxim-und-mascha/"},
    {text: "Maike und Daniel", img: CategoryImage8, link:"/maike-und-daniel/"},
    {text: "Tanja und Thomas", img: CategoryImage6, link:"/tanja-und-thomas/"},
    {text: "Michelle und Marko", img: CategoryImage7, link:"/michelle-und-marko/"},
    {text: "Kathi und Philipp", img: CategoryImage5, link:"/kathi-und-philipp/"},
    {text: "Julia und Jonas", img: CategoryImage4, link:"/julia-und-jonas/"}
  ]
  return (
    <main>
      <Helmet>
      <title>Black Unicorn Wedding Photography</title>
            <meta name="title" content="Black Unicorn Wedding Photography | Hochzeitsfotografie" />
            <meta name="description" content="Hochzeitsfotografie in Rastatt und Berlin. ✔individuell ✔besonders ✔qualitativ " />

      </Helmet>
      <Layout>
        <Navi></Navi>
        <HeroImage link={{text:"Ungestellte Hochzeitsfotografie", url:"/hochzeiten"}} heroimage={heroimage}></HeroImage>
        <Spacer size={1}/>
        <TextBlock size={70} headline={"Hochzeitsfotografie"} text={"Pure, ungestellte Momente - das ist, was mich antreibt. Die Bilder sollen euch widerspiegeln und euch den Tag jedes Mal aufs neue erleben lassen - für immer. Es geht nicht um das perfekte Setting, Licht oder Location, Hauptsache ihr fühlt euch wohl und habt Spaß, denn genau das werden auch die Bilder widerspiegeln. Denn ich möchte Geschichten mit damit erzählen - und hoffentlich auch bald eure."}></TextBlock>
        <Spacer size={1}/>
        <TextBlock headline={"Geschichten"} text={"Jede Hochzeit ist einzigartig. Ich hatte schon die Möglichkeit einige Hochzeiten zu begleiten und emotionale Momente festzuhalten. Stöbert und lasst euch von den hier festgehaltenen Momenten inspirieren."}></TextBlock>
        <CategoriesBlock categoriesContent={categoriesContent}></CategoriesBlock>
        <Spacer size={1}/>
        <HeroImage link={{text:"Hochzeitslocations in Rastatt und Umgebung", url:"/hochzeitsfotografie-rastatt"}} heroimage={rastatthero}></HeroImage>
        <Spacer size={1}/>
        <TextBlock 
            headline={"Hi, schoen euch kennenzulernen!"} 
            text={"Ich freue mich, dass ihr mich kennenlernen wollt! Damit ich schon vor unserem ersten Gespräch alles wichtige weiß, füllt bitte so viel aus, wie ihr schon wisst. Ich freue mich aufs Kennenlernen."}
        />
        <FormBlock></FormBlock>
        <Footer></Footer>
        </Layout>
    </main>
  );
}

export default Index;
