// extracted by mini-css-extract-plugin
export var error = "categoriesBlock-module--error--Gy7SJ";
export var h1 = "categoriesBlock-module--h1--3MxxN";
export var imageLink = "categoriesBlock-module--imageLink--3Lj69";
export var contentItem = "categoriesBlock-module--content__item--AGnG8";
export var link = "categoriesBlock-module--link--1rVe4";
export var linkMneme = "categoriesBlock-module--link--mneme--2IIkx";
export var coverUp = "categoriesBlock-module--coverUp--_2GAJ";
export var categoryImg = "categoriesBlock-module--categoryImg--kewVi";
export var categoryImages = "categoriesBlock-module--categoryImages--3AWa_";
export var categoriesContainer = "categoriesBlock-module--categoriesContainer--1-S96";
export var contentitem = "categoriesBlock-module--contentitem--3YFFq";
export var linkmneme = "categoriesBlock-module--linkmneme--Ic9Xc";