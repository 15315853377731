import React from "react"
import * as styles from "./formBlock.module.scss"
import TextBlock from "../components/textBlock"

export default class FormBlock extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '' ,
            mail: '' ,
            text: '' ,
            datum: '',
            location: '',
            tel: '',
            isSuccessful: "false",
        }
    }

    setName = (newValue) => {
        this.setState({ name: newValue.target.value })
    }

    setTel = (newValue) => {
        this.setState({ tel: newValue.target.value })
    }

    setMail = (newValue) => {
        this.setState({ mail: newValue.target.value })
    }

    setText = (newValue) => {
        this.setState({ text: newValue.target.value })
    }
    
    setDate = (newValue) => {
        this.setState({ datum: newValue.target.value })
    }
    setLocation = (newValue) => {
        this.setState({ location: newValue.target.value })
    }

    sendForm = (event) => {
        const mail = document.getElementById('email');
        const name = document.getElementById('name');
        const isValidEmail = mail.checkValidity();
        const isValidName = name.checkValidity();
        console.log('name:' + isValidName + ' mail: ' + isValidEmail)

        if (isValidEmail===true && isValidName===true){
            name.classList.remove('error');
            mail.classList.remove('error');
            this.setState({ isSuccessful: "loading"});

            fetch(
                './api/date',
                 {
                    method: 'POST',
                    // We convert the React state to JSON and send it as the POST body
                    body: JSON.stringify(this.state),
                    headers: {"content-type":"application/json"}
                }
            ).then((response) => {
                response.json().then(console.log)
                console.log(response.status)
                if(response.status == 200) 
                    {this.setState({ isSuccessful: "success" })
                } else{this.setState({ isSuccessful: "false"})}
                
            });
                
            event.preventDefault();
        }
        if (isValidEmail === false && isValidName === true){
            mail.classList.add('error');
            name.classList.remove('error');
        }
        if (isValidEmail === false && isValidName === false){
            mail.classList.add('error');
            name.classList.add('error');
        }

        if (isValidName===false && isValidEmail===true){
            name.classList.add('error');
            mail.classList.remove('error');
        } else {
            
        }
    }


    render() {
        /**const message = false;*/
        if (this.state.isSuccessful == "success") {
            return (
            <div id="success" className={styles.center}>
                <div>
                    <h2>Danke für deine Nachricht!</h2>
                    <p>Ich werde mich in Kürze bei dir melden!</p>
                </div>
            </div>
            )
        }
        if (this.state.isSuccessful == "loading") {
            return (
            <div id="loader" className={styles.center}>
                <div className={styles.loader}>
                    <div></div>
                    <div></div>
                </div>
            </div>
            )
        }
        else {
            return (<div id="contact">
            <form>
                <div className={styles.form}>
                    <div className={styles.formfield}>
                        <label className={styles.labelDefault}>
                            Dein Name
                            <input type="text" name="Name" id="name" value={this.state.name} onChange={this.setName} required/>
                        </label>
                    </div>
                    <div className={styles.formfield}>
                        <label className={styles.labelDefault}>
                            Deine Mail
                            <input type="email" id="email" name="Mail" value={this.state.mail} onChange={this.setMail} required/>
                        </label>
                    </div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formfield}>
                        <label className={styles.labelTextarea}>
                            Deine Nachricht
                            <textarea name="message" id="message" rows="5" value={this.state.text} onChange={this.setText}/>
                        </label>
                    </div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formfield}>
                        <label className={styles.labelDefault}>
                            Dein Hochzeitsdatum
                            <input type="date" name="Datum" value={this.state.datum} onChange={this.setDate}/>
                        </label>
                    </div>
                    <div className={styles.formfield}>
                        <label className={styles.labelDefault}>
                            Deine Location
                            <input type="text" name="Location" value={this.state.location} onChange={this.setLocation}/>
                        </label>
                    </div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formfield}>
                        <label className={styles.labelDefault}>
                                Deine Telefonnummer
                                <input type="tel" name="tel" value={this.state.tel} onChange={this.setTel}/>
                            </label>
                    </div>
                </div>
                <div className={styles.contentitem}>
                    <button 
                        className={[styles.link,styles.linkmneme].join(' ')}
                        type="submit"
                        onClick={this.sendForm}
                    >
                        Nachricht Senden
                    </button>
                </div>
        </form>
        </div>
        );
        }
    }
}
