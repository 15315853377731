// extracted by mini-css-extract-plugin
export var form = "formBlock-module--form--2LbXY";
export var labelDefault = "formBlock-module--labelDefault--6DDuD";
export var labelTextarea = "formBlock-module--labelTextarea--_sy1L";
export var center = "formBlock-module--center--3JA9Y";
export var loader = "formBlock-module--loader--12lAD";
export var formfield = "formBlock-module--formfield--1YInv";
export var contentitem = "formBlock-module--contentitem--1-UdV";
export var link = "formBlock-module--link--3FbwD";
export var linkmneme = "formBlock-module--linkmneme--2mKip";
export var coverUp = "formBlock-module--coverUp--1dL7l";