import React from "react"
import * as styles from "./categoriesBlock.module.scss"
import { Link } from "gatsby"



export default function category({categoriesContent}){
    const categories = categoriesContent
    return <div className={styles.categoriesContainer}>
        {categories.map((category, i) => (
                <div className={styles.categoryImages} key={i}>
                    <div className={styles.contentitem}>
                        <Link className={[styles.link,styles.linkmneme].join(' ')} to={category.link}>{category.text}</Link>
                    </div>
                    <img className={styles.categoryImg} src={category.img} alt={"test"}></img>
                </div>
        ))}
    </div>
    
}