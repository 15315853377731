import React from 'react'

export default function spacer ({ size }) {
    if(size === 1){
        return<div style={{paddingBottom: "30px"}}/>
    }
    if(size === 2){
        return<div style={{paddingBottom: "60px"}}/>
    }
    if(size === 3){
        return<div style={{paddingBottom: "90px"}}/>
    }

    else{
        return<div style={{paddingBottom: "10px"}}/>
        
    }


}

